
import { mask } from "vue-the-mask"
import { Business } from "@/core/models/Business"
import ApiService from "@/core/services/ApiService"

import { computed, defineComponent, ref, toRefs, watch } from "vue"
import { useRouter } from "vue-router"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { phoneMask } from "@/core/helpers/masks"

export default defineComponent({
  name: "business-component",
  components: {},
  directives: { mask },
  props: ["id", "closed"],
  emits: ["closed", "getlist"],
  setup(props, { emit }) {
    const router = useRouter()
    const { id } = toRefs(props)

    const businessData = ref<Business>({} as Business)

    const ruleFormRef = ref()

    const rules = ref({
      description: getRule(RuleTypes.TEXT50, "Açıklama"),
      email: getRule(RuleTypes.MAIL, "E-mail"),
      phone: getRule(RuleTypes.PHONE, "Telefon"),
      shortTitle: getRule(RuleTypes.TEXT50, "Kısa Ad"),
      title: getRule(RuleTypes.TEXT50, "İşletme Adı"),
    })

    const getBusiness = async id => {
      const { data } = await ApiService.get("business/" + id)
      businessData.value = data
    }

    watch(
      () => props.id,
      newValue => {
        if (newValue) getBusiness(newValue)
      }
    )

    const showDrawer = computed(() => props.id != undefined)

    async function updateBusiness() {
      const data = {
        ...businessData.value,
        phone: businessData.value.phone.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", ""),
        id: props.id,
      }
      await ApiService.post("business/edit", data)
      emit("getlist")
      router.push({ name: "business-list" })
    }

    function update(formEl) {
      if (!formEl) return
      formEl.validate(valid => {
        if (valid) {
          updateBusiness()
        }
      })
    }

    return {
      rules,
      showDrawer,
      activeBusinessId: id,
      handleClose: props.closed,
      businessData,
      update,
      ruleFormRef,
      phoneMask,
    }
  },
})
