
import ApiService from "@/core/services/ApiService"
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import BusinessComponent from "@/components/general/definitions/business/business.vue"
import { useRoute, useRouter } from "vue-router"

export default defineComponent({
  name: "business-list",
  components: {
    BusinessComponent,
  },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const businessList = ref([])
    const filterOnlyActives = ref(true)
    const isLoading = ref(true)
    const newBusiness = ref(false)
    const activeBusinessId = ref<string | undefined>()

    watch(
      () => route.params.business_id,
      newValue => {
        activeBusinessId.value = newValue as string
      }
    )

    watch(
      () => props.add,
      newValue => {
        newBusiness.value = true == newValue
      }
    )

    const businessListFiltered = computed(() => {
      return businessList.value.filter(x => {
        return x["isActive"] == true || !filterOnlyActives.value
      })
    })

    const getBusinessList = async () => {
      isLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data
      isLoading.value = false
    }

    const handleBranchDrawerClose = () => {
      router.push({ name: "business-list" })
    }

    onMounted(async () => {
      activeBusinessId.value = route.params.business_id as string
      newBusiness.value = props.add
      await getBusinessList()
    })

    return {
      businessList,
      Edit,
      filterOnlyActives,
      getBusinessList,
      businessListFiltered,
      isLoading,
      handleBranchDrawerClose,
      activeBusinessId,
      newBusiness,
    }
  },
})
